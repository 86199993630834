import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { TextField } from '@/components/text-field';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchSettings, updateSettings } from '@/services/setting';
import { Button } from '@/components/ui/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { settingSchema } from '@/schema/setting-schema';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/switch';
export const Route = createFileRoute('/console/_authenticated/_layout/settings/_layout/')({
    component: Settings,
});
function Settings() {
    const { t } = useTranslation();
    const { data: settings = {
        vat_rate: '',
        exchange_rate: '',
        invoice_prefix: '',
        invoice_sequence_length: '',
        table_number_min: '',
        table_number_max: '',
        auto_device_approval: false,
    }, refetch, isSuccess, } = useQuery({
        queryKey: ['settings'],
        queryFn: fetchSettings,
    });
    const { mutate } = useMutation({
        mutationFn: updateSettings,
        onSuccess: (data) => {
            refetch().then(() => {
                toast.success(data.message);
            });
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data?.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const form = useForm({
        resolver: yupResolver(settingSchema),
        defaultValues: {
            vatRate: settings['vat_rate'],
            exchangeRate: settings['exchange_rate'],
            invoicePrefix: settings['invoice_prefix'],
            invoiceSequenceLength: settings['invoice_sequence_length'],
            tableNumberMin: settings['table_number_min'],
            tableNumberMax: settings['table_number_max'],
            autoDeviceApproval: settings['auto_device_approval'] === 'true',
        },
    });
    React.useEffect(() => {
        if (isSuccess) {
            form.reset({
                vatRate: settings['vat_rate'],
                exchangeRate: settings['exchange_rate'],
                invoicePrefix: settings['invoice_prefix'],
                invoiceSequenceLength: settings['invoice_sequence_length'],
                tableNumberMin: settings['table_number_min'],
                tableNumberMax: settings['table_number_max'],
                autoDeviceApproval: settings['auto_device_approval'] === 'true',
            });
        }
    }, [form, isSuccess, settings]);
    const onSubmit = React.useCallback((data) => {
        mutate({ ...data, autoDeviceApproval: data.autoDeviceApproval ? 'true' : 'false' });
    }, [mutate]);
    return (<Card>
            <CardHeader>
                <CardTitle>{t('configuration.title', { ns: 'glossary' })}</CardTitle>
                <CardDescription>{t('configuration.description', { ns: 'glossary' })}</CardDescription>
            </CardHeader>
            <CardContent>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                        <TextField control={form.control} name="vatRate" label={t('input.vat.label', { ns: 'glossary' })} description={t('input.vat.description', { ns: 'glossary' })}/>
                        <TextField control={form.control} name="exchangeRate" label={t('input.exchange_rate.label', { ns: 'glossary' })} description={t('input.exchange_rate.description', { ns: 'glossary' })}/>
                        <TextField control={form.control} name="invoicePrefix" label={t('input.invoice_prefix.label', { ns: 'glossary' })} description={t('input.invoice_prefix.description', { ns: 'glossary' })}/>
                        <TextField control={form.control} name="invoiceSequenceLength" label={t('input.invoice_sequence_length.label', { ns: 'glossary' })} description={t('input.invoice_sequence_length.description', { ns: 'glossary' })}/>
                        <div className="flex-row space-x-2 justify-between">
                            <TextField control={form.control} name="tableNumberMin" label={t('input.table_number_min.label', { ns: 'glossary' })} description={t('input.table_number_min.description', { ns: 'glossary' })}/>
                            <TextField control={form.control} name="tableNumberMax" label={t('input.table_number_max.label', { ns: 'glossary' })} description={t('input.table_number_min.description', { ns: 'glossary' })}/>
                        </div>
                        <Switch control={form.control} name="auto_device_approval" label={t('input.auto_device_approval.label', { ns: 'glossary' })} description={t('input.auto_device_approval.description', { ns: 'glossary' })}/>
                        <Button className="w-full" type="submit">
                            {t('update')}
                        </Button>
                    </form>
                </Form>
            </CardContent>
        </Card>);
}
