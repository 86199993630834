import React from 'react';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '@/schema/login-schema';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { TextField } from '@/components/text-field';
import { Icon } from '@iconify/react';
import { useMutation } from '@tanstack/react-query';
import { login } from '@/services/login';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { useAuthentication } from '@/hooks/use-authentication';
import { useTranslation } from 'react-i18next';
export const Route = createFileRoute('/console/login')({
    component: Login,
    validateSearch: (search) => {
        return {
            redirect: search.redirect || '/console',
        };
    },
    beforeLoad: ({ context, search }) => {
        if (context.authentication?.isAuthenticated) {
            throw redirect({ to: search.redirect || '/console' });
        }
    },
});
function Login() {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    const { redirect } = Route.useSearch();
    const navigate = useNavigate();
    const { user, setUsername } = useAuthentication();
    const form = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const { isPending, mutate } = useMutation({
        mutationFn: login,
        onSuccess: (data) => {
            sessionStorage.setItem('username', data.user.username);
            sessionStorage.setItem('locale', data.user.locale || 'en');
            setUsername(data.user.username);
        },
        onError: (data) => {
            const message = isAxiosError(data) ? data.response?.data.message : t('login.failed', { ns: 'glossary' });
            toast.error(message, { closeButton: true });
        },
    });
    React.useEffect(() => {
        if (user) {
            navigate(redirect).then();
        }
    }, [navigate, redirect, user]);
    const onSubmit = (data) => {
        mutate(data);
    };
    return (<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
                    {t('login_title', { ns: 'glossary' })}
                </h4>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2">
                        <div className="space-y-4">
                            <TextField control={form.control} name="username" label={t('username')} autoComplete="username"/>
                            <TextField control={form.control} name="password" label={t('password')} type={showPassword ? 'text' : 'password'} autoComplete="current-password" endIcon={<Button variant="outline" size="icon" onClick={() => {
                setShowPassword((prevState) => !prevState);
            }}>
                                        <Icon icon={showPassword ? 'clarity:eye-solid' : 'clarity:eye-hide-solid'}/>
                                    </Button>}/>
                            <Button disabled={isPending} type="submit" className="w-full">
                                {isPending ? <Icon icon="svg-spinners:blocks-shuffle-3"/> : t('login')}
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>);
}
