export const getRoleLabel = (roles) => {
    if (roles.includes('ROLE_SUPER_ADMIN')) {
        return 'Super Admin';
    }
    if (roles.includes('ROLE_ADMIN')) {
        return 'Admin';
    }
    if (roles.includes('ROLE_EMPLOYEE')) {
        return 'Employee';
    }
    if (roles.includes('ROLE_CASHIER')) {
        return 'Cashier';
    }
    if (roles.includes('ROLE_DELIVERER')) {
        return 'Deliverer';
    }
    return '';
};
export const getRole = (roles) => {
    if (roles.includes('ROLE_SUPER_ADMIN')) {
        return 'ROLE_SUPER_ADMIN';
    }
    if (roles.includes('ROLE_ADMIN')) {
        return 'ROLE_ADMIN';
    }
    if (roles.includes('ROLE_CASHIER')) {
        return 'ROLE_CASHIER';
    }
    if (roles.includes('ROLE_DELIVERER')) {
        return 'ROLE_DELIVERER';
    }
    return 'ROLE_EMPLOYEE';
};
