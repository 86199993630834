import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, Eye, Trash2, UserRoundPlus } from 'lucide-react';
import { useReactTable } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteUser, fetchUsers, patchUser } from '@/services/user';
import { Table } from '@/components/table';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { UserEnabledSwitch } from '@/components/user-enabled-switch';
import { getRole } from '@/lib/role';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { RoleOptions } from '@/constants/role';
import { LocaleOptions } from '@/constants/locale';
import { useTranslation } from 'react-i18next';
export const Route = createFileRoute('/console/_authenticated/_layout/users/')({
    component: Users,
});
const columnHelper = createColumnHelper();
function Users() {
    const navigate = Route.useNavigate();
    const { t } = useTranslation();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const { data = [], refetch } = useQuery({
        queryKey: ['users'],
        queryFn: () => fetchUsers(),
    });
    const { mutate } = useMutation({
        mutationFn: deleteUser,
        onSuccess: (data) => {
            toast.success(data.message);
            refetch().then();
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const { mutate: patch } = useMutation({
        mutationFn: patchUser,
        onSuccess: (data) => {
            refetch().then(() => {
                toast.success(data.message);
            });
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const handleChange = React.useCallback((username, data) => {
        patch({ username, data });
    }, [patch]);
    const columns = React.useMemo(() => [
        columnHelper.accessor('usernameCanonical', {
            id: 'view',
            header: () => t('view'),
            cell: (info) => (<Button variant="ghost" size="icon" onClick={() => {
                    navigate({ to: `/console/users/${info.getValue()}` }).then();
                }}>
                        <Eye />
                    </Button>),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('username', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('username')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('usernameCanonical', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('canonical_username')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('firstName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('first_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('lastName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('last_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('roles', {
            header: () => t('role'),
            cell: (info) => (<Select onValueChange={(value) => handleChange(info.row.original.usernameCanonical, { roles: [value] })} value={getRole(info.getValue())}>
                        <SelectTrigger>
                            <SelectValue placeholder={t('placeholder.select.role', { ns: 'glossary' })}/>
                        </SelectTrigger>
                        <SelectContent>
                            {RoleOptions.map((option, index) => (<SelectItem key={`select-item-${index}}`} value={option.value} disabled={option.disabled}>
                                    {option.label}
                                </SelectItem>))}
                        </SelectContent>
                    </Select>),
            footer: (info) => info.column.id,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('locale', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('locale')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => (<Select onValueChange={(locale) => handleChange(info.row.original.usernameCanonical, { locale })} value={info.getValue()}>
                        <SelectTrigger>
                            <SelectValue placeholder={t('placeholder.select.locale', { ns: 'glossary' })}/>
                        </SelectTrigger>
                        <SelectContent>
                            {LocaleOptions.map((option, index) => (<SelectItem key={`select-item-${index}}`} value={option.value}>
                                    {option.label}
                                </SelectItem>))}
                        </SelectContent>
                    </Select>),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'right' },
            },
        }),
        columnHelper.accessor('enabled', {
            header: () => t('enabled'),
            cell: (info) => <UserEnabledSwitch user={info.row.original}/>,
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('usernameCanonical', {
            id: 'actions',
            enableHiding: false,
            header: () => t('actions'),
            cell: ({ getValue }) => {
                return (<Button variant="ghost" className="text-red-500" onClick={() => {
                        mutate(getValue());
                    }}>
                            <Trash2 />
                        </Button>);
            },
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
    ], [handleChange, mutate, navigate, t]);
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    const onClickNew = () => {
        navigate({ to: '/console/users/new' }).then();
    };
    return (<div className="w-full">
            <div className="py-4">
                <Button onClick={onClickNew}>
                    <UserRoundPlus />
                    {t('new.user.title', { ns: 'glossary' })}
                </Button>
            </div>
            <Table table={table} columns={columns}/>
        </div>);
}
