import React from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Grid2X2Plus } from 'lucide-react';
import { Form } from '@/components/ui/form';
import { TextField } from '@/components/text-field';
import { TextAreaField } from '@/components/textarea-field';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { categorySchema } from '@/schema/category-schema';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCategory } from '@/services/category';
import { toast } from 'sonner';
import { useBoolean } from 'react-use';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
export const NewCategoryButton = ({ iconOnly, onCategoryCreated }) => {
    const { t } = useTranslation();
    const [open, onToggle] = useBoolean(false);
    const queryClient = useQueryClient();
    const form = useForm({
        resolver: yupResolver(categorySchema),
        defaultValues: {
            name: '',
            description: '',
        },
    });
    const { mutate, isPending } = useMutation({
        mutationFn: createCategory,
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries({ queryKey: ['categories'] }).then(() => {
                onToggle();
                form.reset({ name: '', description: '' });
                if (onCategoryCreated) {
                    onCategoryCreated(data.category);
                }
            });
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const onSubmit = React.useCallback((data) => {
        mutate(data);
    }, [mutate]);
    return (<Dialog open={open} onOpenChange={onToggle}>
            <DialogTrigger asChild>
                <Button size={iconOnly ? 'icon' : 'default'}>
                    {iconOnly ? (<Grid2X2Plus />) : (<React.Fragment>
                            <Grid2X2Plus /> {t('new.category.title', { ns: 'glossary' })}
                        </React.Fragment>)}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{t('new.category.title', { ns: 'glossary' })}</DialogTitle>
                    <DialogDescription>{t('new.category.description', { ns: 'glossary' })}</DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                        <TextField control={form.control} name="name" label={t('name')}/>
                        <TextAreaField control={form.control} name="description" label={t('description')}/>
                        <DialogFooter>
                            <Button disabled={isPending} type="submit" className="w-full">
                                {isPending ? <Icon icon="svg-spinners:blocks-shuffle-3"/> : t('save')}
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>);
};
