import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, Eye } from 'lucide-react';
import { format } from 'date-fns';
import { DATETIME_FORMAT } from '@/constants/date';
import { getStateBadge } from '@/workflow/order';
import { currencyFormat, getUserFullName } from '@/lib/string';
import { useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Table } from '@/components/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
const columnHelper = createColumnHelper();
export const OrderTable = ({ orders }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const columns = React.useMemo(() => [
        columnHelper.accessor('referenceNumber', {
            header: () => t('view'),
            cell: (info) => (<Button onClick={() => {
                    navigate({ to: `/console/orders/${info.getValue()}` }).then();
                }} size="icon" variant="ghost">
                        <Eye />
                    </Button>),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('invoiceNumber', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('invoice_number')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('createdAt', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('created_at')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => format(info.getValue(), DATETIME_FORMAT),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('updatedAt', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('updated_at')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => format(info.getValue(), DATETIME_FORMAT),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('state', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('state')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => getStateBadge(info.getValue()),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('subtotalAmount', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('subtotal')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => currencyFormat(Number(info.renderValue())),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('vatAmount', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('vat')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => currencyFormat(Number(info.renderValue())),
            footer: (info) => info.column.id,
            enableSorting: false,
            enableColumnFilter: false,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('totalAmount', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('total.dollar')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => currencyFormat(Number(info.renderValue())),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('totalAmountInRiel', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('total.riel')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => currencyFormat(Number(info.renderValue())),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('user', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('user')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (props) => getUserFullName(props.getValue()),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
    ], [navigate, t]);
    const table = useReactTable({
        data: orders,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    return <Table table={table} columns={columns}/>;
};
