import React from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { Toaster } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BreadcrumbProvider } from '@/providers/bread-crumb-provider';
const TanStackRouterDevtools = process.env.NODE_ENV === 'production'
    ? () => null
    : React.lazy(() => import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
    })));
export const Route = createRootRouteWithContext()({
    component: () => (<TooltipProvider>
            <BreadcrumbProvider>
                <Outlet />
                <Toaster position="top-right" richColors toastOptions={{
            closeButton: true,
        }}/>
                <TanStackRouterDevtools position="top-right"/>
                <ReactQueryDevtools position="bottom" initialIsOpen={false}/>
            </BreadcrumbProvider>
        </TooltipProvider>),
});
