import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { ThemeProvider } from '@/providers/theme-provider';
import { useAuthentication } from '@/hooks/use-authentication';
import { AuthenticationProvider } from '@/providers/authentication-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@/i18next';
import './styles/globals.css';
import { LanguageProvider } from '@/providers/language-provider';
const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    context: {
        authentication: undefined,
    },
});
const applicationId = sessionStorage.getItem('applicationId') || 'root';
const Application = () => {
    const authentication = useAuthentication();
    return <RouterProvider router={router} context={{ authentication }}/>;
};
const queryClient = new QueryClient();
// Render the app
const rootElement = document.querySelector(`#${applicationId}`);
if (rootElement && !rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<StrictMode>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <AuthenticationProvider>
                        <LanguageProvider>
                            <Application />
                        </LanguageProvider>
                    </AuthenticationProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </StrictMode>);
}
