import i18next from '@/i18next';
export const currencyFormat = (value, currency = 'USD') => new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value);
export const getRoleName = (role) => {
    switch (role) {
        case 'ROLE_EMPLOYEE':
            return i18next.t('employee');
        case 'ROLE_CASHIER':
            return i18next.t('cashier');
        case 'ROLE_DELIVERER':
            return i18next.t('deliverer');
        case 'ROLE_ADMIN':
            return i18next.t('admin');
        default:
            return i18next.t('super_admin');
    }
};
export const getLocaleLabel = (locale) => {
    switch (locale) {
        case 'en':
            return 'English';
        case 'fr':
            return 'Français';
        default:
            return 'ភាសាខ្មែរ';
    }
};
export const getUserFullName = (user) => `${user.firstName} ${user.lastName}`;
