import React from 'react';
import { Switch } from '@/components/ui/switch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchProduct } from '@/services/product';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { Label } from '@/components/ui/label';
import { useTranslation } from 'react-i18next';
export const ProductAvailableSwitch = ({ product, withLabel, queryKey = ['products'], }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: patchProduct,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey }).then(() => {
                toast.success(data.message);
            });
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const handleChange = React.useCallback(() => {
        mutate({ identifier: product.identifier });
    }, [mutate, product.identifier]);
    return withLabel ? (<div className="flex items-center space-x-2">
            <Label htmlFor={product.canonicalName}>{t('available')}</Label>
            <Switch id={product.canonicalName} checked={product.available} onCheckedChange={handleChange}/>
        </div>) : (<Switch id={product.canonicalName} checked={product.available} onCheckedChange={handleChange}/>);
};
