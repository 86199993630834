import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { TodayOrderTable } from '@/components/today-order-table';
export const Route = createFileRoute('/console/_authenticated/_layout/')({
    component: Index,
});
function Index() {
    return (<div>
            <TodayOrderTable />
        </div>);
}
