import React, { useId } from 'react';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
export const Radio = ({ control, name, label, options, orientation }) => {
    const id = useId();
    return (<FormField control={control} name={name} render={({ field }) => (<FormItem className="space-y-3">
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                        <RadioGroup orientation={orientation} onValueChange={field.onChange} defaultValue={field.value}>
                            {options.map((option, index) => (<FormItem className="flex items-center space-x-3 space-y-0" key={`${id}-${index}`}>
                                    <FormControl>
                                        <RadioGroupItem disabled={option.disabled} value={option.value}/>
                                    </FormControl>
                                    <FormLabel className="font-normal">{option.label}</FormLabel>
                                </FormItem>))}
                        </RadioGroup>
                    </FormControl>
                    <FormMessage />
                </FormItem>)}/>);
};
