import React from 'react';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
export const SelectField = ({ control, name, label, placeholder, disabled, options, description, }) => (<div className="flex flex-row space-x-2 items-end">
        <FormField control={control} name={name} render={({ field }) => (<FormItem>
                    {label && <FormLabel>{label}</FormLabel>}
                    <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                            <SelectTrigger disabled={disabled} className="w-full">
                                <SelectValue placeholder={placeholder}/>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                            <SelectItem value="0">{placeholder}</SelectItem>
                            {options.map((option, index) => (<SelectItem key={`select-item-${index}}`} value={option.value} disabled={option.disabled}>
                                    {option.label}
                                </SelectItem>))}
                        </SelectContent>
                    </Select>
                    <FormMessage />
                    {description && <FormDescription>{description}</FormDescription>}
                </FormItem>)}/>
    </div>);
