import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchOrders } from '@/services/order';
import { useTranslation } from 'react-i18next';
import { OrderTable } from '@/components/order-table';
import { OrderState } from '@/types/Order';
export const TodayOrderTable = () => {
    const { t } = useTranslation();
    const { data = [] } = useQuery({
        queryKey: ['today-orders'],
        queryFn: () => fetchOrders({
            from: new Date(),
            to: new Date(),
            state: OrderState.OPEN,
            invoiceNumber: '',
        }),
    });
    return (<div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min p-3 space-y-2">
            <h3 className="text-lg">{t('today_orders')}</h3>
            <OrderTable orders={data}/>
        </div>);
};
