import React from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import i18next from '@/i18next';
export const getStateBadge = (state, className) => {
    if (['approved'].some((item) => item === state)) {
        return <Badge className={className}>{i18next.t('common:approved')}</Badge>;
    }
    if ('denied' === state) {
        return (<Badge className={cn('bg-red-500', className)} color="danger">
                {i18next.t('common:denied')}
            </Badge>);
    }
    return <Badge className={cn('bg-yellow-500', className)}>{i18next.t('common:pending')}</Badge>;
};
export var DeviceState;
(function (DeviceState) {
    DeviceState["APPROVED"] = "approved";
    DeviceState["DENIED"] = "denied";
    DeviceState["PENDING"] = "pending";
})(DeviceState || (DeviceState = {}));
