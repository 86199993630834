import React from 'react';
const MEDIA = '(prefers-color-scheme: dark)';
const initialState = {
    theme: 'system',
    systemTheme: undefined,
    setTheme: () => null,
};
const ThemeProviderContext = React.createContext(initialState);
export function ThemeProvider({ children, defaultTheme = 'system', storageKey = 'symflow-ui-theme', ...props }) {
    const [resolvedTheme] = React.useState(() => getTheme(storageKey));
    const [theme, setTheme] = React.useState(() => localStorage.getItem(storageKey) || defaultTheme);
    React.useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove('light', 'dark');
        if (theme === 'system') {
            const systemTheme = window.matchMedia(MEDIA).matches ? 'dark' : 'light';
            root.classList.add(systemTheme);
            return;
        }
        root.classList.add(theme);
    }, [theme]);
    const value = {
        theme,
        systemTheme: resolvedTheme,
        setTheme: (theme) => {
            localStorage.setItem(storageKey, theme);
            setTheme(theme);
        },
    };
    return (<ThemeProviderContext.Provider {...props} value={value}>
            {children}
        </ThemeProviderContext.Provider>);
}
export const useTheme = () => {
    const context = React.useContext(ThemeProviderContext);
    if (context === undefined)
        throw new Error('useTheme must be used within a ThemeProvider');
    return context;
};
const getTheme = (key, fallback) => {
    let theme;
    try {
        theme = localStorage.getItem(key) || undefined;
    }
    catch (e) {
        console.error(e);
    }
    return theme || fallback;
};
