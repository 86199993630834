import { apiAxios } from '@/lib/apiAxios';
import { formatISO } from 'date-fns';
export const fetchUsers = () => apiAxios.get('/users').then((response) => response.data);
export const fetchCurrentUser = () => apiAxios.get('/users/me').then((response) => response.data);
export const fetchUser = (username) => apiAxios.get(`/users/${username}`).then((response) => response.data);
export const createUser = async ({ data, imageFile }) => {
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('plainPassword', data.password);
    formData.append('roles[]', data.role);
    if (data.dob) {
        formData.append('dob', formatISO(data.dob));
    }
    formData.append('imageFile', imageFile);
    const response = await apiAxios.post('/users', formData);
    return response.data;
};
export const updateUser = ({ username, data }) => apiAxios.put(`/users/${username}`, data).then((response) => response.data);
export const deleteUser = (username) => apiAxios.delete(`/users/${username}`).then((response) => response.data);
export const patchUser = ({ username, data }) => apiAxios.patch(`/users/${username}`, data).then((response) => response.data);
export const uploadUserImage = async ({ username, imageFile }) => {
    const formData = new FormData();
    formData.append('imageFile', imageFile);
    const response = await apiAxios.post(`/users/${username}/uploads`, formData);
    return response.data;
};
export const userChangePassword = ({ username, data, }) => apiAxios
    .put(`/users/${username}/change-password`, data)
    .then((response) => response.data);
