import React from 'react';
import { createFileRoute, Link, Outlet, useRouterState } from '@tanstack/react-router';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle, } from '@/components/ui/navigation-menu';
import { useTranslation } from 'react-i18next';
export const Route = createFileRoute('/console/_authenticated/_layout/users/$username/_layout')({
    component: Layout,
});
function Layout() {
    const { username } = Route.useParams();
    const { t } = useTranslation();
    const { location: { pathname }, } = useRouterState();
    const tabs = React.useMemo(() => [
        { label: t('information'), path: `/console/users/${username}` },
        { label: t('access_keys'), path: `/console/users/${username}/access-keys` },
        { label: t('change_password'), path: `/console/users/${username}/change-password` },
    ], [t, username]);
    return (<div className="space-y-2 py-4">
            <NavigationMenu>
                <NavigationMenuList>
                    {tabs.map((tab, index) => (<NavigationMenuItem key={`navigation-menu-item-user-${username}-${index}`}>
                            <NavigationMenuLink active={tab.path === pathname} className={navigationMenuTriggerStyle()} asChild>
                                <Link to={tab.path}>{tab.label}</Link>
                            </NavigationMenuLink>
                        </NavigationMenuItem>))}
                </NavigationMenuList>
            </NavigationMenu>
            <Outlet />
        </div>);
}
