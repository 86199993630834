import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, Pencil, Trash2 } from 'lucide-react';
import { useReactTable } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteCategory, fetchCategories } from '@/services/category';
import { Table } from '@/components/table';
import { NewCategoryButton } from '@/components/new-category-button';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
export const Route = createFileRoute('/console/_authenticated/_layout/categories/')({
    component: Categories,
});
const columnHelper = createColumnHelper();
function Categories() {
    const { t } = useTranslation();
    const navigate = Route.useNavigate();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const { data = [], refetch } = useQuery({
        queryKey: ['categories'],
        queryFn: fetchCategories,
    });
    const { mutate } = useMutation({
        mutationFn: deleteCategory,
        onSuccess: (data) => {
            toast.success(data.message);
            refetch().then();
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('id')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('name', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('canonicalName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('canonical_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('canonicalName', {
            id: 'actions',
            enableHiding: false,
            header: () => t('actions'),
            cell: ({ getValue }) => {
                return (<React.Fragment>
                            <Button variant="ghost" onClick={() => {
                        navigate({ to: `/console/categories/${getValue()}` }).then();
                    }}>
                                <Pencil />
                            </Button>
                            <Button variant="ghost" className="text-red-500" onClick={() => {
                        mutate(getValue());
                    }}>
                                <Trash2 />
                            </Button>
                        </React.Fragment>);
            },
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
    ], [mutate, navigate, t]);
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    return (<div className="w-full">
            <div className="py-4">
                <NewCategoryButton />
            </div>
            <Table table={table} columns={columns}/>
        </div>);
}
