import React from 'react';
import { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, } from '@/components/ui/sidebar';
import { ChevronUp, FileBox, LayoutDashboard, LogOut, MonitorSmartphone, PackageSearch, Settings, Shapes, User2, Users, } from 'lucide-react';
import { Link } from '@tanstack/react-router';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from '@/components/ui/dropdown-menu';
import { useAuthentication } from '@/hooks/use-authentication';
import i18next from '@/i18next';
import { useTranslation } from 'react-i18next';
const items = [
    {
        title: i18next.t('dashboard'),
        url: '/console',
        icon: LayoutDashboard,
    },
    {
        title: i18next.t('products'),
        url: '/console/products',
        icon: PackageSearch,
    },
    {
        title: i18next.t('orders'),
        url: '/console/orders',
        icon: FileBox,
    },
    {
        title: i18next.t('categories'),
        url: '/console/categories',
        icon: Shapes,
    },
    {
        title: i18next.t('devices'),
        url: '/console/devices',
        icon: MonitorSmartphone,
    },
    {
        title: i18next.t('users'),
        url: '/console/users',
        icon: Users,
    },
    {
        title: i18next.t('settings'),
        url: '/console/settings',
        icon: Settings,
    },
];
export const AppSidebar = () => {
    const { user } = useAuthentication();
    const { t } = useTranslation();
    return (<Sidebar>
            <SidebarHeader>
                <h1 className="text-2xl font-bold">Beef & Basil</h1>
                <p className="text-sm text-gray-500">{t('admin_console')}</p>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>{t('application')}</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {items.map((item) => (<SidebarMenuItem key={item.title}>
                                    <SidebarMenuButton asChild>
                                        <Link to={item.url}>
                                            <item.icon />
                                            <span>{item.title}</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <SidebarMenuButton>
                                    <User2 /> {user?.username}
                                    <ChevronUp className="ml-auto"/>
                                </SidebarMenuButton>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent side="top" className="w-[--radix-popper-anchor-width]">
                                <DropdownMenuItem>
                                    <a href="/console/logout" className="text-red-500 w-full flex flex-row space-x-2 items-center">
                                        <LogOut className="text-red-500"/>
                                        <span>{t('logout')}</span>
                                    </a>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarFooter>
        </Sidebar>);
};
