import React from 'react';
import { Switch } from '@/components/ui/switch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchUser } from '@/services/user';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { Label } from '@/components/ui/label';
import { useTranslation } from 'react-i18next';
export const UserEnabledSwitch = ({ user, withLabel, queryKey = ['users'] }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: patchUser,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey }).then(() => {
                toast.success(data.message);
            });
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const handleChange = React.useCallback(() => {
        mutate({ username: user.usernameCanonical });
    }, [mutate, user.usernameCanonical]);
    return withLabel ? (<div className="flex items-center space-x-2">
            <Label htmlFor={user.usernameCanonical}>{t('enabled')}</Label>
            <Switch id={user.usernameCanonical} checked={user.enabled} onCheckedChange={handleChange}/>
        </div>) : (<Switch id={user.usernameCanonical} checked={user.enabled} onCheckedChange={handleChange}/>);
};
