import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { PackagePlus, Search } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { fetchProducts } from '@/services/product';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ProductTable } from '@/components/product-table';
import { Form } from '@/components/ui/form';
import { TextField } from '@/components/text-field';
import { SelectField } from '@/components/select-field';
import { fetchCategories } from '@/services/category';
import { Radio } from '@/components/radio';
export const Route = createFileRoute('/console/_authenticated/_layout/products/')({
    component: Products,
});
function Products() {
    const { t } = useTranslation();
    const navigate = Route.useNavigate();
    const form = useForm({
        defaultValues: {
            name: '',
            category: '0',
            available: 'all',
        },
    });
    const { data = [], refetch } = useQuery({
        queryKey: ['products'],
        queryFn: () => fetchProducts({
            ...form.getValues(),
            category: form.getValues('category') !== '0' ? form.getValues('category') : undefined,
            available: form.getValues('available') !== 'all' ? form.getValues('available') : undefined,
        }),
    });
    const { data: categories = [] } = useQuery({
        queryKey: ['categories'],
        queryFn: fetchCategories,
    });
    const onClickNew = () => {
        navigate({ to: '/console/products/new' }).then();
    };
    const onClickSearch = () => {
        refetch().then();
    };
    const categoryOptions = React.useMemo(() => categories.map((category) => ({ label: category.name, value: category.id.toString() })), [categories]);
    return (<div className="w-full">
            <div className="py-4 border rounded-lg p-2 space-y-2 mb-2">
                <Form {...form}>
                    <div className="flex flex-row space-x-2 items-center justify-center w-full">
                        <TextField control={form.control} name="name" label={t('product.name')} placeholder={t('placeholder.product.name', { ns: 'glossary' })}/>
                        <SelectField control={form.control} name="category" options={categoryOptions} label={t('category')} placeholder={t('placeholder.select.category', { ns: 'glossary' })}/>
                        <Radio control={form.control} name="available" options={[
            { label: t('all'), value: 'all' },
            { label: t('available'), value: 'true' },
            { label: t('unavailable'), value: 'false' },
        ]} label={t('product.available')} orientation="horizontal"/>
                    </div>
                </Form>
                <div className="flex flex-row justify-center items-center space-x-2">
                    <Button variant="outline" className="bg-yellow-500 text-background" onClick={onClickSearch}>
                        <Search />
                        {t('search')}
                    </Button>
                    <Button onClick={onClickNew}>
                        <PackagePlus />
                        {t('new.product.title', { ns: 'glossary' })}
                    </Button>
                </div>
            </div>
            <ProductTable products={data}/>
        </div>);
}
