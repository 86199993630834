import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, } from '@tanstack/table-core';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp, CheckCircle, CircleX, Eye, Trash2 } from 'lucide-react';
import { useReactTable } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Table } from '@/components/table';
import { toast } from 'sonner';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { fetchDevices, deleteDevice, transitionDevice } from '@/services/device';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@/constants/date';
import { DeviceState, getStateBadge } from '@/workflow/device';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger, } from '@/components/ui/alert-dialog';
export const Route = createFileRoute('/console/_authenticated/_layout/devices/')({
    component: Devices,
});
const columnHelper = createColumnHelper();
function Devices() {
    const { t } = useTranslation();
    const navigate = Route.useNavigate();
    const [sorting, setSorting] = React.useState([]);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState({});
    const { data = [], refetch } = useQuery({
        queryKey: ['devices'],
        queryFn: () => fetchDevices(),
    });
    const { mutate: transitionMutation } = useMutation({
        mutationFn: transitionDevice,
        onSuccess: (data) => {
            toast.success(data.message);
            refetch().then();
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const { mutate: deleteMutation } = useMutation({
        mutationFn: deleteDevice,
        onSuccess: (data) => {
            toast.success(data.message);
            refetch().then();
        },
        onError: (error) => {
            const message = isAxiosError(error)
                ? error.response?.data.message
                : t('error_occurred', { ns: 'glossary' });
            toast.error(message);
        },
    });
    const columns = React.useMemo(() => [
        columnHelper.accessor('webToken', {
            header: () => t('view'),
            cell: (info) => (<Button size="icon" onClick={() => {
                    navigate({ to: `/console/devices/${info.getValue()}` }).then();
                }}>
                        <Eye />
                    </Button>),
            footer: (info) => info.column.id,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('deviceName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('device_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('deviceType', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('device_type')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('osName', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('os_name')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('modelName', {
            header: () => t('model_name'),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('brand', {
            header: () => t('brand'),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('manufacturer', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('manufacturer')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => info.renderValue(),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('state', {
            header: ({ column }) => (<Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                        {t('state')}
                        {column.getIsSorted() === 'asc' ? <ArrowUp /> : <ArrowDown />}
                    </Button>),
            cell: (info) => getStateBadge(info.getValue()),
            footer: (info) => info.column.id,
        }),
        columnHelper.accessor('createdAt', {
            header: () => t('created_at'),
            cell: (info) => format(info.getValue(), DATE_FORMAT),
            footer: (info) => info.column.id,
            meta: {
                style: { textAlign: 'center' },
            },
        }),
        columnHelper.accessor('updatedAt', {
            header: () => t('updated_at'),
            cell: (info) => format(info.getValue(), DATE_FORMAT),
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
        }),
        columnHelper.accessor('webToken', {
            id: 'actions',
            enableHiding: false,
            header: () => t('actions'),
            cell: ({ getValue, row: { original: { state }, }, }) => {
                const buttons = [
                    <AlertDialog key={`device-web-token-${getValue()}-button-delete`}>
                            <AlertDialogTrigger asChild>
                                <Button variant="ghost" size="icon" className="text-red-500">
                                    <Trash2 />
                                    {t('delete')}
                                </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>{t('delete.device.title', { ns: 'glossary' })}</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        {t('delete.device.description', { ns: 'glossary' })}
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>{t('close')}</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => {
                            deleteMutation(getValue());
                        }}>
                                        {t('continue')}
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>,
                ];
                if (state !== DeviceState.DENIED) {
                    buttons.push(<Button variant="ghost" size="icon" onClick={() => {
                            transitionMutation({ webToken: getValue(), transition: 'deny' });
                        }} key={`device-web-token-${getValue()}-button-deny`} className="text-red-500">
                                <CircleX />
                                {t('deny')}
                            </Button>);
                }
                if (state !== DeviceState.APPROVED) {
                    buttons.push(<Button variant="ghost" size="icon" onClick={() => {
                            transitionMutation({ webToken: getValue(), transition: 'approve' });
                        }} key={`device-web-token-${getValue()}-button-approve`} className="text-primary">
                                <CheckCircle />
                            </Button>);
                }
                return buttons;
            },
        }),
    ], [deleteMutation, navigate, t, transitionMutation]);
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });
    return (<div className="w-full">
            <Table table={table} columns={columns}/>
        </div>);
}
