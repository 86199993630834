import { apiAxios } from '@/lib/apiAxios';
import { round } from '@/lib/number';
const getFormattedData = (data) => {
    const vatAmount = round((Number(data.totalAmount) * Number(data.vatRate)) / 100);
    return {
        ...data,
        items: data.items.map((item) => ({
            ...item,
            totalAmount: round(Number(item.price) * Number(item.quantity)),
            product: item.product.id,
        })),
        vatAmount,
        subtotalAmount: round(Number(data.totalAmount) - vatAmount),
        totalAmount: round(Number(data.totalAmount)),
        totalAmountInRiel: round(Number(data.totalAmount) * Number(data.exchangeRate)),
    };
};
export const fetchOrders = async (searchOrder) => apiAxios
    .get('/orders', searchOrder && {
    params: {
        ...searchOrder,
        state: undefined,
        from: searchOrder.from?.toISOString(),
        to: searchOrder.to?.toISOString(),
        states: [searchOrder.state],
    },
})
    .then((response) => response.data);
export const fetchOrder = async (referenceNumber) => apiAxios.get(`/orders/${referenceNumber}`).then((response) => response.data);
export const createOrder = async (order) => apiAxios
    .post('/orders', getFormattedData(order))
    .then((response) => response.data);
export const updateOrder = async ({ referenceNumber, data }) => apiAxios
    .put(`/orders/${referenceNumber}`, getFormattedData(data))
    .then((response) => response.data);
export const payOrder = async ({ referenceNumber, data }) => apiAxios
    .patch(`/orders/${referenceNumber}/transitions/pay`, { ...data, paymentMethod: data.paymentMethod })
    .then((response) => response.data);
export const cancelOrder = async (referenceNumber) => apiAxios
    .patch(`/orders/${referenceNumber}/transitions/cancel`)
    .then((response) => response.data);
