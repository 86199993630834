import React from 'react';
import { LanguageContext } from '@/contexts/language-context';
import i18next from '@/i18next';
import { toast } from 'sonner';
import { useAuthentication } from '@/hooks/use-authentication';
export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = React.useState('en');
    const { user, isAuthenticated } = useAuthentication();
    const updateLocale = React.useCallback((locale) => {
        i18next
            .changeLanguage(locale)
            .then(() => {
            setLocale(locale);
        })
            .catch((error) => {
            toast.error(error.message);
        });
    }, []);
    React.useEffect(() => {
        if (isAuthenticated && user) {
            updateLocale(user.locale || 'en');
        }
    }, [isAuthenticated, user, updateLocale]);
    return <LanguageContext.Provider value={{ locale, updateLocale }}>{children}</LanguageContext.Provider>;
};
