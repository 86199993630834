import React from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import i18next from '@/i18next';
export const getStateBadge = (state, className) => {
    if (['paid', 'completed'].some((item) => item === state)) {
        return <Badge className={className}>{i18next.t('common:paid')}</Badge>;
    }
    if ('cancelled' === state) {
        return (<Badge className={cn('bg-red-500', className)} color="danger">
                {i18next.t('common:cancelled')}
            </Badge>);
    }
    return <Badge className={cn('bg-yellow-500', className)}>{i18next.t('common:pending')}</Badge>;
};
